import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
// import { TranslationModule } from './modules/translation.module';
// import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { CommonModule } from '@angular/common';
import { TranslationModule } from './modules/translation.module';
import { NoRouteComponent } from './no-route/no-route.component';
@NgModule({
    declarations: [EditProfileComponent, CommonModalComponent, NoRouteComponent],
    imports: [CommonModule,  TranslationModule, ReactiveFormsModule,],
    exports: [EditProfileComponent, CommonModalComponent, NoRouteComponent]
  })
  export class SharedModule { }