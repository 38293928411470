import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyStoriesGarageComponent } from './pages/my-stories-garage/my-stories-garage.component';
import { ShareRaceComponent } from './pages/share-race/share-race.component';
import { CiamConfirmLinkComponent } from './pages/ciam-confirm-link/ciam-confirm-link.component';
import { CiamLinkComponent } from './pages/ciam-link/ciam-link.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { AnonymizeComponent } from './pages/anonymize/anonymize.component';


const routes: Routes = [

      {
        path: 'experience/:id',
        component: ShareRaceComponent, // Loads moments or race share page, depending on id
      },

      {  
        path: ':participantId/my-race-details',
        component: CiamLinkComponent,
      },
      {
        path: 'link-participant',
        component: CiamConfirmLinkComponent,
      },
    
     
      {
        path: 'verify/:partipationInfo',
        component: VerifyComponent,
      },
      {
        path: 'anonymize/:partipationInfo',
        component:AnonymizeComponent,
      },
     
      {
        path: ':ciamId',
        children: [],
        component: MyStoriesGarageComponent,
      },
      {
        path: ':ciamId/:vinOrFin',
        component: MyStoriesGarageComponent,
      },

      {
        path:'',
        component:MyStoriesGarageComponent,
      },
     
      
    ]
  


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class StoriesRoutingModule { }