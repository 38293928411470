import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {
	selectedLanguage: string = 'en';
	baseURL = environment.newraceUrl;	// new api url, will be uncommented post testing
	// baseURL = environment.raceUrl;	

	httpOptions: any;

	constructor(private http: HttpClient) {
		
	}



	getRaceShare(id: string) {
		let	url = this.baseURL + 'acp/v1/stories/'+ id;	 // new api url, will be uncommented post testing
		// let urlBlock = window.location.href.split('/');
		// let url;
		// if (urlBlock[5] === 'details' || urlBlock[5] === 'experience') {
		// 	url = this.baseURL + 'api/v2/customer_ciam/races/'+ id;	
		// } else {
		// 	url = this.baseURL + 'api/v1/customer_ciam/races/'+ id;	
		// }	
		return this.http.get(url, this.httpOptions);
	}

	getPublicRaceShare(id: string) {
		let	url = this.baseURL + 'web/v1/stories/'+ id;	 // offroad enablement
		return this.http.get(url, this.httpOptions);
	}

	getPublicRaceData(id: string) {	 // Api not being used
		// let url = this.baseURL + 'api/v2/public/races/'+ id;
		let urlBlock = window.location.href.split('/');
		let url;
		if (urlBlock[5] === 'details' || urlBlock[5] === 'experience') {
			url = this.baseURL + 'api/v2/public/races/'+ id;		
		} else {
			url = this.baseURL + 'api/v1/public/races/'+ id;		
		}	
		return this.http.get(url);
	}

	publishRace(raceId: string) {   // Api not being used
		const url = this.baseURL + 'api/v1/customer_ciam/races/'+ raceId;		
		return this.http.put(url, {'isShared': true}, this.httpOptions);
	}



	
	getCarImage(raceId: string) {
		const url = this.baseURL + 'api/v1/race/getVehicleImage?id=' + raceId;
		return this.http.get(url, this.httpOptions);
	}
	
	
	getCarName(vin: string) {
		const url = this.baseURL + 'api/v1/trackPace/getVehicleModelName?vinNumber=' + vin;
		return this.http.get(url, this.httpOptions);
	}



}
