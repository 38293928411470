
<div class="usr-screen ciam-landing" *ngIf="isShowSpinner; else SpinnerLoads" >
    <div *ngFor="let event of eventInfo?.exercises">
    <div class="usr-event-card-rectangle">
      <div class="img-reg">
        <div class="image" style="background-image: url({{eventInfo.event_banner_url?eventInfo.event_banner_url:defaultBanner}})"></div>
      </div>
      <div class="info-txt-box">
        <div class="info-center">
          <h2>{{"CP_SM_ONGOING_EVENT_TITLE"| customTranslation : translationData}}</h2>
          <p class="your-race-data-is-in">
            <span>{{"CP_SM_CIAMLINK_MESSAGE" | customTranslation : translationData}}</span>
          </p>
        </div>
        <div class="info-panel-bottom">
          <h2 class="Best-Time-Challenge">{{ eventInfo.eventName }}</h2>
        </div>
        <div class="info-exercise">
          <div class="tag-item red">{{ event.exerciseName}}</div>
        </div>
        <div class="go-to-leaderboard">
          
            <a href="{{event.rankingListUrl}}" target="_blank" class="leaderboard-btn"><button class="btn-primary">
              {{"CP_SM_LEADERBOARD_BTN_LBL" | customTranslation : translationData}}
            </button></a>
          
        </div>
    </div>
    </div>
</div>
</div>
<ng-template #SpinnerLoads>
  <div class="spinner-border spinner"></div>
</ng-template>




